.Login__root_2nme {
  position: relative;
  z-index: 200;
  display: block;
  width: 360px;
}

  @media (max-width: 767px) {.Login__root_2nme {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    min-height: inherit;
    border: none;
    border-radius: 0;
  }
}

.Login__page_34Z2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  min-height: calc(100vh - 84px);
  padding: 20px 0 20px 215px;
}

.Login__page_34Z2 .Login__root_2nme {
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;
}

@media (min-width: 768px) {

.Login__page_34Z2 .Login__root_2nme {
      padding: 40px 32px 32px;
      border-radius: 16px;
      box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
  }
}

.Login__page_34Z2:after {
    min-height: inherit;
    font-size: 0;
    content: '';
}

@media (max-width: 767px) {

.Login__page_34Z2 {
    display: block;
    padding: 0;
  }
}
